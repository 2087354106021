<template>
  <b-container fluid class="mt-4">
    <!-- Barra de Control -->
    <div class="row my-4">
      <div class="col-sm-12 offset-sm-0 col-md-8 offset-md-0 co-lg-8">
        <!--    Filter  -->
        <b-form-group
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              v-model="filterValue"
              type="search"
              id="filterInput"
              placeholder="Buscar por Código de SAP"
              @keyup.enter ="FilterData()"
              @keyup.esc ="clear()"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="default"
                        size="sm"
                        :disabled="!filterValue"
                        @click="clear()">
                <b-icon-x/>
              </b-button>
              <b-button variant="primary"
                        size="sm"
                        @click="FilterData()" >
                <b-icon-search/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!--    Filter  -->
      </div>
      <div class="col-sm-12 offset-sm-0 col-md-4 offset-md-0 col-lg-4 ">
        <!--    Sort & Paginate  -->
        <b-form-group>
          <b-input-group>
            <b-form-select v-model="sortBy" :options="sortByOptions" @change="FilterData()" class="w-50" size="sm" />
            <b-form-select v-model="sortDirection" :options="sortOptions" @change="FilterData()" class="w-25" size="sm" :disabled="!sortBy"/>
            <b-form-select v-model="perPage"  :options="pageOptions" @change="FilterData()" class="w-25" size="sm"/>
          </b-input-group>
        </b-form-group>
        <!--    Sort & Paginate  -->
      </div>
    </div>
    <div class="row my-2" >
      <slot name="filters"/>
    </div>
  </b-container>
</template>
<script>
export default {
  name: 'DetailCatalogBar',
  props: {
    sortByOptions: Array,
  },
  data() {
    return {
      filterValue: '',
      sortOptions: [
        { value: '', text: 'Orden', disabled: true },
        { value: '', text: 'Asc' },
        { value: '-', text: 'Desc' },
      ],
      sortBy: '',
      sortDirection: '',
      currentPage: 1,
      perPage: 15,
      pageOptions: [
        { value: 15, text: '#', disabled: true },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
        { value: 50, text: '50' },
      ],
    }
  },
  watch: {
    filterValue(newValue) {
      this.$emit('searchValue', newValue)
      this.$emit('currentPageValue', 1)
    },
    sortBy(newValue) {
      this.$emit('sortByValue', newValue)
    },
    sortDirection(newValue) {
      this.$emit('sortDirectionValue', newValue)
    },
    perPage(newValue) {
      this.$emit('perPageValue', newValue)
    },
    currentPage(newValue) {
      this.$emit('currentPageValue', newValue)
    },
  },
  methods: {
    FilterData() {
      this.$emit('getData')
    },
    clear() {
      this.filterValue = ''
      this.currentPage = 1
      this.$emit('currentPageValue', 1)
      this.$emit('searchValue', '')
      this.$emit('getData')
    },
  },
}
</script>
