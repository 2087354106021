<template>
  <div>
    <a-table
      :scroll="{x:1300}"
      bordered
      :dataSource="tableObject.data"
      :columns="tableObject.columns"
      :loading="tableObject.loading"
      :row-key="record => record.code"
      :pagination="tableObject.pagination"
      @change="refreshResults"
    >
      <div slot="filterDropdown"
           slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
           class='custom-filter-dropdown'>
        <a-input
          v-ant-ref="c => searchInput = c"
          :placeholder="`Buscar por ${column.title}`"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block;"
        />
        <a-button
          type='primary'
          @click="() => handleSearch(selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >Buscar</a-button>
        <a-button
          @click="() => handleReset(clearFilters)"
          size="small"
          style="width: 90px"
        >Limpiar</a-button>
      </div>
      <a-icon slot="filterIcon" type='search' slot-scope="filtered" :style="{ color: filtered ? '#108ee9' : undefined }"/>
      <template slot="customRender" slot-scope="text">
        <span v-if="searchText">
          <template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{fragment}}</mark>
            <template v-else>{{fragment}}</template>
          </template>
        </span>
        <template v-else>{{text}}</template>
      </template>
      <!-- Columnas Personalizadas -->
      <template slot="sapBatches" slot-scope="data">
        {{data.sapBatches.batch_code}} - {{data.sapBatches.dated_at}}
      </template>
      <template slot="sapTransactions" slot-scope="data">
        {{data.sapTransactions.code}} - {{data.sapTransactions.name}}
      </template>
      <template slot="status" slot-scope="record">
        <strong v-if="record === 'Pendiente'" class="text-warning">
          <b-icon icon="clock-history" class="font-weight-bold"></b-icon> <strong>{{record}}</strong>
        </strong>
        <strong  v-else class="text-success">
          <b-icon icon="check" class="font-weight-bold"></b-icon> <strong>{{record}}</strong>
        </strong>
      </template>
      <div slot="expandedRowRender" slot-scope="record" class="bg-gray-3 p-5">
        <div class="row justify-content-center">
          <span class="text-center" v-if="record.has_errors === false && record.response_description === null">
            No existen detalles - {{record.has_errors}}
          </span>
          <template v-if="record.has_errors === true" class="mb-4">
            <div class="col-4 text-center">
              <b-icon icon="exclamation-triangle" variant="danger"/>
              <span class="text-danger">Contiene Errores</span>
            </div>
          </template>
          <div class="col-12 mt-2 text-center" v-if="record.response_description !== null">
            <small> {{ record.response_description }} </small>
          </div>
        </div>
      </div>
      <a slot="action" slot-scope="data">
        <responsive-button variant="secondary"
          pill size="sm" responsive="md"
          text="Editar" icon="pencil-square"
          @ClickEvent="showModal('edit',data)"
          v-if="typeTable === 'catalogs'"
        />
      </a>
    </a-table>
  </div>
</template>

<script>
export default {
  name: 'SapDetailTable',
  props: {
    typeTable: {
      default: 'catalogs',
      type: String,
    },
    tableObject: {
      type: [Object],
      required: true,
    },
  },
  data () {
    return {
      // Varialbles propias para el funcionamiento de la tabla
      searchText: '',
      searchInput: null,
      // Varialbles propias para el funcionamiento de la tabla
    }
  },
  watch: {
    searchInput(newValue) {
      this.$emit('searchInput', newValue)
    },
  },
  methods: {
    handleSearch (selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    showModal(action, data = {}) {
      this.$emit('Modal', action, data)
    },
    Selected(data = {}) {
      this.$emit('Select', data)
    },
    refreshResults(paginationEvent = {}) {
      this.$emit('Filter', paginationEvent)
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
