<template>
  <div>
    <div class="air__utils__heading">
      <h5>{{resourceName}}</h5>
    </div>
    <my-breadcrumbs :routes="routes" v-show="componentInitialized === 'gestión'"/>
    <!-- Barra de Búsqueda -->
    <detail-catalog-bar @getData="getData"
                        :sortByOptions="sortByOptions"
                        @sortByValue="sortByValue = $event"
                        @searchValue="searchValue = $event"
                        @sortDirectionValue="sortDirectionValue = $event"
                        @perPageValue="perPageValue = $event"
                        @currentPageValue="currentPageValue = $event"
                        v-show="componentInitialized === 'gestión'"
    >
      <template slot="filters">
        <div class="col-sm-12 col-md-6">
          <b-form-group
            id="status" label-for="status" label="Estatus">
            <b-form-select v-model="statusFilter" :options="['Pendiente', 'Realizado']"
                           id="status" label="Estatus" @change="getData()">
              <template slot="first">
                <b-form-select-option value="" >Todos los Estatus</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6">
          <b-form-group
            id="batch" label-for="batch" label="Batch">
            <b-form-select v-model="sapBatchFilter" :options="sapBatches"
                           id="batch" label="Batch" @change="getData()"
                           value-field="jsonApi.id" text-field='batch_code'>
              <template slot="first">
                <b-form-select-option value="" >Todos los Batch</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
<!--        <div class="col-sm-12 col-md-12 col-lg-4">-->
<!--          <b-form-group-->
<!--            id="sap_code" label-for="sap_code" label="Código de SAP">-->
<!--            <b-form-select v-model="sapCodeFilter" :options="['Pendiente', 'Realizado']"-->
<!--                           id="sap_code" label="Código de SAP" @change="getData()">-->
<!--              <template slot="first">-->
<!--                <b-form-select-option value="" >Todos los Códigos</b-form-select-option>-->
<!--              </template>-->
<!--            </b-form-select>-->
<!--          </b-form-group>-->
<!--        </div>-->
      </template>
    </detail-catalog-bar>

    <!--Botonera  -->
    <div class="row justify-content-center pb-2" v-show="componentInitialized === 'gestión'">
      <div class="col-sm-12 offset-sm-0 col-lg-2 offset-lg-10 text-right">
        <b-button @click="OpenModal('store')" pill block variant="primary" >
          <b-icon-plus/> Duplicar
        </b-button>
      </div>
    </div>
    <!-- Modal -->
    <b-modal :title="`Detalle ${resourceName}`"
             ref="modal-form"
             size="lg"
    >
      <b-container fluid>
        <!-- Form with Model -->
        <a-form-model
          ref="rule-Form"
          :model="form"
          :rules="rules"
          layout="vertical"
          :wrapper-col="{ span: 24}"
        >
          <a-form-model-item ref="sap_batch_id" label="Batch" prop="sap_batch_id" >
            <a-select v-model="form.sap_batch_id"
              placeholder="Batch"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option  v-for="option in sapBatches" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.batch_code}} - {{option.dated_at}}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item ref="sap_transaction_id" label="Transacción" prop="sap_transaction_id" >
            <a-select v-model="form.sap_transaction_id"
              placeholder="Transacción"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option  v-for="option in sapTransactions" :key="option.jsonApi.id" :value="option.jsonApi.id" >
                {{option.code}} - {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <div v-show="!enabledField">
            <div class="row justify-content-center" v-show="!load">
              <responsive-button variant="primary" pill size="sm" text="Guardar" responsive="md" icon="archive-fill" @ClickEvent="onSubmit"/>
            </div>
          </div>
        </a-form-model>
        <!-- Form with Model -->

        <!-- Spinner -->
        <my-spinner :load="load"/>

      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- Tabla -->
    <sap-detail-table :tableObject="tableObject"
                          @Modal="OpenModal" @Filter="getData"
                          @searchInput="searchInput = $event"
    />
  </div>
</template>

<script>
import SapDetailTable from '../components/table'
import { notification } from 'ant-design-vue'
import DetailCatalogBar from '../components/searchBar'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import { generalServiceFormMixin } from '@/mixins/serviceFormMixins/generalServiceFormMixin'
export default {
  name: 'servicePlanningSapForm',
  props: {
    componentInitialized: {
      default: 'gestión',
      type: [String],
      required: false,
    },
    folio: {
      default: '',
      type: [String],
      required: false,
    },
    serviceId: {
      default: '0',
      type: [String],
      required: false,
    },
  },
  components: {
    SapDetailTable,
    DetailCatalogBar,
  },
  mixins: [
    generalServiceFormMixin,
    getGeneralMixin,
  ],
  data() {
    return {
      // <!-- Constantes -->
      checked: false,
      resource: 'servicePlanningSaps',
      resourceName: 'Planeación de Servicio SAP',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Planeación de Servicio SAP',
          aIcon: '',
        },
      ],
      searchInput: null,
      // <!-- Table -->
      tableObject: {
        data: [],
        columns: [
          {
            title: 'Código de SAP',
            dataIndex: 'sap_code',
            key: 'sap_code',
            class: 'small text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) => record.sap_code.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'SAP Batch',
            dataIndex: '',
            key: 'sapBatches',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'sapBatches',
            },
          },
          {
            title: 'SAP Transactions',
            dataIndex: '',
            key: 'sapTransactions',
            class: 'small text-center',
            scopedSlots: {
              customRender: 'sapTransactions',
            },
          },
          {
            title: 'Fecha de Transacción en SAP',
            dataIndex: 'dated_at',
            key: 'dated_at',
            class: 'small text-center',
          },
          {
            title: 'Fecha de Ejecución en SAP',
            dataIndex: 'executed_at',
            key: 'executed_at',
            class: 'small text-center',
          },
          {
            title: 'Estatus de la Transacción',
            dataIndex: 'status',
            key: 'status',
            class: 'small text-center',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'status',
            },
            onFilter: (value, record) => record.status.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          }],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
      },
      // <!-- Table -->
      // <!-- Barra de Control -->
      searchValue: '',
      sortByValue: '',
      sortByDefaultValue: '-code',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'code', text: 'Clave en EcoNet' },
        { value: 'sap_code', text: 'Clave de SAP' },
        { value: 'status', text: 'Estatus de la Transacción' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->
      // <!-- Constantes -->
      // <!-- Form with Model -->
      viewSapCode: false,
      enabledField: false,
      form: {
        sap_batch_id: undefined,
        sap_transaction_id: undefined,
      },
      // arrays selects
      sapTransactions: [],
      sapBatches: [],
      // Reglas de validación
      rules: {
        sap_batch_id: [
          { required: true, message: 'El campo Batch es obligatorio.', trigger: 'blur' },
        ],
        sap_transaction_id: [
          { required: true, message: 'La Transacción es obligatorio.', trigger: 'blur' },
        ],
      },
      // <!-- Form with Model -->
      load: false,
      // Filtros
      statusFilter: '',
      sapBatchFilter: '',
      sapCodeFilter: '',
    }
  },
  computed: {
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return this.sortByDefaultValue
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    statusOnChange(value) {
      const REJECTED_STATUS = 'Rechazado'
      const PENDING_STATUS = 'Pendiente'
      if (value === REJECTED_STATUS) {
        this.form.rejected_check = true
      } else {
        this.form.rejected_check = false
      }
      if (value !== PENDING_STATUS) {
        this.viewSapCode = true
      } else {
        this.viewSapCode = false
        this.form.sap_code = ''
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    getData(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.tableObject.pagination.current = paginationEvent.current
        this.tableObject.pagination.pageSize = paginationEvent.pageSize
      }
      this.tableObject.loading = true
      let params = {}
      if (this.componentInitialized === 'servicios') {
        params = {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
          'page[size]': `${this.perPageValue}`,
          sort: `${this.sortDirection}${this.sortValue}`,
          include: 'sapTransactions,sapBatches',
        }
      } else {
        params = {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.currentPageValue}`,
          'page[size]': `${this.perPageValue}`,
          sort: `${this.sortDirection}${this.sortValue}`,
          include: 'sapTransactions,sapBatches',
        }
      }

      if (this.searchValue !== '') params['filter[sapCode]'] = this.searchValue
      if (this.statusFilter !== '') params['filter[status]'] = this.statusFilter
      if (this.sapBatchFilter !== '') params['filter[sapBatches]'] = this.sapBatchFilter

      this.$store.dispatch('jv/get', [this.resource, { params }])
        .then(response => {
          this.tableObject.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.tableObject.data = objectArray(tableData)
          this.tableObject.pagination = resolvePagination(jsonApiPagination)
        })
    },
    // <!-- Get,Paginate,Search & Filter Data  -->
    // <!-- Get array selects data  -->
    async getSapTransactions() {
      await this.getResourceData({}, 'sapTransactions')
      this.sapTransactions = this.generalResponse
    },
    async getSapBatches() {
      await this.getResourceData({}, 'sapBatches')
      this.sapBatches = this.generalResponse
    },
    // <!-- Get array selects data  -->
    // <!-- Open & Close Modal Logic  -->
    OpenModal(action, data = {}) {
      this.$refs['modal-form'].show()
      this.getSapTransactions()
      this.getSapBatches()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.sap_batch_id = undefined
        this.sap_transaction_id = undefined
      } else {
        // Update case
        this.id = data.sapBatches.jsonApi.id
        this.sap_batch_id = data.sapTransactions.jsonApi.id
      }
    },
    CloseModal() {
      this.$refs['modal-form'].hide()
      this.resetForm()
    },
    // <!-- Open & Close Modal Logic  -->
    // <!-- Store Logic  -->
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          // build the relationships with the form data
          const relationships = {
            sapBatches: {
              data: { id: this.form.sap_batch_id, type: 'sapBatches' },
            },
            sapTransactions: {
              data: { id: this.form.sap_transaction_id, type: 'sapTransactions' },
            },
          }
          // Store Object
          const formObject = {
            jsonApi: {
              type: this.resource,
              relationships: relationships,
            },
          }
          // Envio del Objeto
          this.load = true
          this.$store.dispatch('jv/post', [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro guardado exitosamente',
              })
              this.getData()
              this.load = false
              this.CloseModal()
            })
            .catch(() => {
              this.load = false
            })
          // Envio del Objeto
          // }
        } else {
          // Fallo de validación
          return false
        }
      })
    },
    // <!-- Store Logic  -->
    resetForm() {
      this.$refs['rule-Form'].resetFields()
    },
  },
  mounted() {
    this.getData()
    this.getSapBatches()
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
